(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {
        /**
         *  Init WOW.js
         *  @link https://github.com/matthieua/WOW
         */
        initWOW: function() {
            var wow = new WOW(
              {
                boxClass:     'animate',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       0,          // distance to the element when triggering the animation (default is 0)
              }
            );
            wow.init();
        },
        /**
         * Init FitVids
         * @link http://fitvidsjs.com/
         */
        initFitVids: function() {
          $("body").fitVids();
        },
        /**
         * Loads the social media "share" links into a new, small window
         */
        shareFunctionality: function (){
            $('.share-post a.social-link').click(function(e) {
                e.preventDefault();
                window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                return false;
            });
        },

        /**
         * Mobile Menu functionality
         */
        mobileMenu: function() {
          $(".menu-trigger-container").on("click touchstart", function(e) {
              e.preventDefault();
              if ($('body').hasClass("menu-open")) {
                  $('body').removeClass("menu-open");
              } else {
                  $('body').addClass("menu-open");
              }
          });

          $('.menus.mobile .has-mega > .link-wrapper > a').on("click", function(e) {
              e.preventDefault();
              $(this).parent().next().slideToggle();
              $(this).parent().parent().toggleClass('active-sub');
              
          });

          $('.menus.mobile .has-children > .link-wrapper > a').on("click", function(e) {
            e.preventDefault();
            $(this).next().slideToggle();
            $(this).parent().parent().toggleClass('active-sub');
            
          });

        },

        /**
         * Scroll to anchor links
         */
        scrollToAnchorLinks: function() {
          $(".wrap a[href^='#']").on("click", function(e) {
              e.preventDefault();
              href = $(this).attr("href");

              if (href.length > 1) {
                  $("html, body").animate(
                      {
                          scrollTop: $(href).offset().top - 165
                      },
                      500
                  );
              }
          });
        },
        
        /**
         * Bio slide toggle
         */
        bioSlideToggle: function() {
            $(document).on('click', '.content-team-member--bio-toggle', function(e){
              e.preventDefault();
              $(this).toggleClass('open');
              $(this).next().slideToggle();
            });
        },

        /**
         * Updates PHP search parameters
         */
        updateSearchParameters: function(uri, key, value) {

          var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
          var separator = uri.indexOf('?') !== -1 ? "&" : "?";
          if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
          }
          else {
            return uri + separator + key + "=" + value;
          }

        },

        /**
         * Accordion
         */
        accordion: function() {
            // expand selected accordion
            $(document).on('click', '.accordion--title', function(e){
              $(this).next().slideToggle();
              $(this).toggleClass('active');
            });
        },

        /**
         * Banner ticker
         * DEPRECATED
         */
        bannerTicker: function() {
            scrollMove('.banner--background-images',60 ,1 );
        },

        /**
         * Generic filter change
         */
        
        genericFilterChange: function() {

            $(document).on('change', '.select_submit-form-on-change', function(){
                $(this).closest('form').submit();
            });

        },

        /**
         * Search field functionality
         */
        
        searchField: function() {
            $('.utility-menu--search a').on('click', function(e) {
                e.preventDefault();
                $('.search-form-overlay').fadeIn('fast');
                $('input.search-field').focus();
            });

            $('.search-form-overlay--close').on('click', function() {
              $('.search-form-overlay').fadeOut('fast');
            });
        },

        /**
         * Gallery for inspirations block
         */
        
        inspirationsGallery: function() {

            $().fancybox({
                selector : '[data-fancybox]',
                toolbar: true,
                baseClass : 'max-width',
                buttons : [
                    'close'
                ], 
            });
            
        },

        /**
         * Mobile reveal more projects button
         */
        
        mobileRevealMoreProjects: function() {
            $('.featured-projects--mobile-more-trigger').on('click', function(e) {
                e.preventDefault();
                $('.featured-projects--mobile-more-trigger').remove();
                $('.featured-projects--row').addClass('reveal-more-projects');
            });

        },

        /**
         * Inspirations Isotope
         */
        
        inspriationsIsotope: function() {

            // init Isotope
            var $grid = $('.inspiration--grid').isotope({
                itemSelector: '.inspiration--item'
            });
            // layout Isotope after each image loads
            $grid.imagesLoaded().progress( function() {
                $grid.isotope('layout');
            });

        },
        /**
         * Sticky Blog Sidebar
         */
        stickyBlogSidebar: function() {
          $sticky_args = {
            offset_top: 130
          };
          $("#post-sidebar").stick_in_parent($sticky_args);
        }
        ,

        /**
         * FAQ Selector drop down
         */
        
        faqSelectorDropDown: function() {

            $('.faq-selector--faq-select').on('change', function() {
                $("html, body").animate(
                      {
                          scrollTop: $( this.value ).offset().top - 65
                      },
                      500
                  );
            });

        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper