(function($, window, document) { // jQuery wrapper

    // Space Designer Functions
    window.designer = {

        /**
         * Designer Events
         */
        
        designerEvents: function() {


            // Next and Previous button functionality
            $(document).on('click', '.space-designer--direction', function(e){
              // scroll to the top of the page
              $("html, body").animate({ scrollTop: 0 }, "slow");

              window.ajax.loadDesignerStep(e, $(this));

            });

            // Step 1 functionality
            $(document).on('change', 'input[name="type_select"]', function(e){
                window.designer.designerStep1(e, $(this));
            });

            // Step 2 functionality
            $(document).on('change', 'input[name="area_select"]', function(e){
                window.designer.designerStep2(e, $(this));
            });
            // Step 3 functionality
            $(document).on('change', 'input[name="product_name_select"]', function(e){
                window.designer.designerStep3(e, $(this));
            });

         
        },

        /**
         * Step 1 Functionality
         */
        designerStep1: function($e, $this) {

          
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'type', $this.val() );
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'area', '' );
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'product_name', '' );
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'swatch_index', '' );
          

          $('.designer-value.step-2, .designer-value.step-3, .designer-value.step-4').val('');
          $('#type').val( $this.val() );
          $('.space-designer--next').removeClass('disabled');

          $image = $this.data('dynamic-image');
          //window.designer.dynamicImage($image);
          window.ajax.loadDesignerStep($e, $this);

        },

         /**
         * Step 2 Functionality
         */
        designerStep2: function($e, $this) {
          
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'area', $this.val() );
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'product_name', '' );
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'swatch_index', '' );

          $('.designer-value.step-3, .designer-value.step-4').val('');
          $('#area').val( $this.val() );
          $('.space-designer--next').removeClass('disabled');

          $image = $this.data('dynamic-image');
          //window.designer.dynamicImage($image);
          window.ajax.loadDesignerStep($e, $this);

        },
        /**
         * Step 3 functionalityy
         */
        designerStep3: function($e, $this) {
          
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'product_name', $this.val() );
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'swatch_index', '' );

          $('.designer-value.step-4').val('');
          $('#product_name').val( $this.val() );
          $('.space-designer--next').removeClass('disabled');

          $image = $this.data('dynamic-image');
          //window.designer.dynamicImage($image);
          window.ajax.loadDesignerStep($e, $this);

        },
      
        /**
         * Step 4 Functionality
         */
        designerStep4: function($e, $this) {

          $swatch_index_val = $this.data('swatch-index');
          window.designer.pushDesignerHistory( designerVars.ajaxurl, 'swatch_index', $swatch_index_val );         

          $('#swatch_index').val( $swatch_index_val );
          $('.space-designer--next').removeClass('disabled');

          $image = $this.data('swatch-image');
          //window.designer.dynamicImage($image);
          window.ajax.loadDesignerStep($e, $this);

        },

        pushDesignerHistory: function($url, $paramName, $paramData) {
            $newState = window.PageFunctions.updateSearchParameters($url, $paramName, $paramData);
            designerVars.ajaxurl = $newState;
            history.replaceState(null, null, $newState);
        },

        dynamicImage: function($image) {

          $imageBGWrap = $('.space-designer--image-wrap');
          $imageBGElement = $('.space-designer--dynamic-image');

          window.product.replaceImage($imageBGElement, $imageBGWrap, $image);
        }


    };

}(window.jQuery, window, document)); // End of jQuery wrapper