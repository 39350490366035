(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    window.sliders = {

    	/**
    	 * Global load more button functionality
    	 */
        initSlider: function ($el, $args){
        	$el.slick($args);
        },

		/**
         * Products page gallery slider
         */
        productGallery: function() {
			
			$el = $('.single-product--gallery-slider');
			$args = {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				adaptiveHeight: true,
				prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon-left-chevron"></i></button>',
				nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon-right-chevron"></i></button>',
				autoplay: true,
  				autoplaySpeed: 5000
			};

        	window.sliders.initSlider($el, $args);

        },

        /**
         * Products page related products slider
         */
        productRelatedProducts: function() {
			
			$el = $('.single-product--related-products-slider');
			$args = {
	            slidesToShow: 2,
	            slidesToScroll: 1,
	            arrows: true,
	            adaptiveHeight: true,
	            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon-left-chevron"></i></button>',
	            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon-right-chevron"></i></button>',
	            responsive: [
	              {
	                breakpoint: 781,
	                settings: {
	                  slidesToShow: 1,
	                }
	              }   
	            ]
	        }; 

	        window.sliders.initSlider($el, $args);

        },

        /**
         * "why woodtone" slider on the series page
         */
        seriesWhy: function() {
        	$el = $('.why-woodtone-items-slider');
			$args = {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            dots:true,
	            arrows: true,
	            adaptiveHeight: true,
	            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon-left-chevron"></i></button>',
	            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon-right-chevron"></i></button>',
	            appendDots: $(".why-woodtone-items-dots"),
	            autoplay: true,
  				autoplaySpeed: 5000
	        };

	        window.sliders.initSlider($el, $args);

        },

        /**
         * Single Project Gallery
         */
        singleProjectGallery: function() {
        	$el = $('.slider-for');
			$args = {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            arrows: true,
	            fade: false,
	            asNavFor: '.slider-nav',
	            adaptiveHeight: true,
	            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon-left-chevron"></i></button>',
	            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon-right-chevron"></i></button>'
	        };

	        window.sliders.initSlider($el, $args);
	        
        },

        /**
         * Single Project Gallery Nav
         */
        singleProjectGalleryNav: function() {
        	$el = $('.slider-nav');
			$args = {
	            slidesToShow: 5,
	            slidesToScroll: 1,
	            asNavFor: '.slider-for',
	            dots: false,
	            arrows:false,   
	            centerMode: true,
	            focusOnSelect: true
	          };

	        window.sliders.initSlider($el, $args);
        },

        /**
         * Single Project Gallery Nav
         */
        singleProjectRelatedProjects: function() {
        	$el = $('.project--related-projects-slider');
			$args = {
	            slidesToShow: 2,
	            slidesToScroll: 1,
	            arrows: true,
	            fade: false,
	            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon-left-chevron"></i></button>',
	            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon-right-chevron"></i></button>',
	            responsive: [
	            {
	              breakpoint: 781,
	              settings: {
	                slidesToShow: 1,
	                slidesToScroll: 1
	              }
	            }   
	            ]
	        };

	        window.sliders.initSlider($el, $args);

        },
        /**
         * Home Page slider
         */
        homePageSlider: function() {
        	$el = $('.banner--background-images');
			$args = {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            dots: false,
	            arrows:false,   
	            centerMode: false,
	            focusOnSelect: false,
	            autoplay: true,
  				autoplaySpeed: 4000
	          };

	        window.sliders.initSlider($el, $args);
        },

    };

}(window.jQuery, window, document)); // End of jQuery wrapper