(function($, window, document) { // jQuery wrapper

    // Calculator Functions
    window.calculator = {

        /**
         * Calculator Events
         */
        
        calculatorEvents: function() {

            // Add a wall
            $('.calculator--add-wall').on('click', function(e) {
                window.calculator.addWall(e);
            });

            // remove a wall
            $(document).on('click', '.calculator--remove-wall', function(e){
                window.calculator.removeWall(e, $(this));
            });

            // calculate
            $( "#calculate" ).submit(function( e ) {
                window.calculator.calculate(e);
            });
        },
        /**
         * Add Wall
         */
        addWall: function(e) {            
            
              e.preventDefault();
              wallNumber.number++;
             
              $clone = $('.calculator--wall:first-of-type').clone();

              $clone.find('.calculator--calculations').append('<a href="#" class="calculator--remove-wall animate fadeIn delay-25s">×</a>')

              if ( $clone.find('.calculator--results_title').html() != wallNumber.resultsTitle) {
                $clone.find('.calculator--results_title').html(wallNumber.resultsTitle)
              }
             

              $clone.find('input').val('');
              $clone.attr('data-number', wallNumber.number);
              $clone.find('.calculator--wall_number span').html(wallNumber.number);
              $clone.insertBefore('.calculator--controls_wrap');


        },

        /**
         * Remove wall
         */
        removeWall: function(e, $this) {

            e.preventDefault();
            wallNumber.number--;

            // dont edit items before the deleted item
            $threshold = $this.parent().parent('.calculator--wall').attr('data-number');

            // remove the selected item
            $this.parent().parent('.calculator--wall').remove();
  

            // subtraction of wall number on all subsequent items            
            $( ".calculator--wall" ).each(function() {

              $dataNumber = $(this).attr('data-number');

              if ($dataNumber > $threshold ) {

                $newNumber = parseInt($dataNumber) - 1;
                $(this).attr('data-number', $newNumber);
                $(this).find('.calculator--wall_number span').html($newNumber);
                
              }


            });

        },
        /**
         * Make sure we have data in all our inputs
         */
        validate: function($widthInput, $heightInput, $exposureSelect, $this) {

            $valid = true;

            if ($widthInput.val() == '' || $widthInput.val() < 1) {
              $widthInput.addClass('invalid');
              $valid = false;
              }
            if ($heightInput.val() == '' || $heightInput.val() < 1) {
              $heightInput.addClass('invalid');
              $valid = false;
            }
            if ($exposureSelect.val() == '' || $exposureSelect.val() < 1) {
              $exposureSelect.addClass('invalid');
              $valid = false;
            }          

            if ($valid) {

              // remove our validation classes if valid
              $widthInput.removeClass('invalid');
              $heightInput.removeClass('invalid');
              $exposureSelect.removeClass('invalid');

              return true;

            } else {
              if ( $this.find('.calculator--results_title').html() != wallNumber.resultsTitle) {
                $this.find('.calculator--results_title').html(wallNumber.resultsTitle)
              }
              return false;
            }

        },

        /**
         * Update the user display with the calculated values and messaging
         */
        calculate: function(e) {

            e.preventDefault();

            $( ".calculator--wall" ).each(function() {
                
                $sidingType = $(this).find('#exposure :selected').text();

                $widthInput = $(this).find('#width');
                $heightInput = $(this).find('#height');
                $exposureSelect = $(this).find('#exposure');

                if ( window.calculator.validate( $widthInput, $heightInput, $exposureSelect, $(this) ) ) {

                  $w = $widthInput.val();
                  $h = $heightInput.val();
                  $e = $exposureSelect.val();
                  
                  
                  $area = window.calculator.formula($w,$h,$e); 

                  $(this).find('.calculator--results_title').html('You require <span class="area">'+ $area + '</span> linear feet of ' + $sidingType + '.');
                }

            });

        },

        /**
         * Calculate the linear feet + add 10%
         */
        formula: function($w,$h,$e) {

            $sqft = $w * $h;
            $sqft_exposure = ($sqft / $e) * 12;
            $total = $sqft_exposure * 1.1;

            return Math.ceil($total);
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper