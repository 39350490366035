(function($, window, document) { // jQuery wrapper

    // Product related functions
    window.product = {

    	/**
		 * Replace the preview image
		 */
		replaceImage: function($element, $wrap, $image) {

			$wrap.addClass('loading');
			$element.fadeOut(250, function() {

				$element.css('background-image', 'url(' + $image + ')');

				var image = new Image();

				image.onload = function () {
					$element.fadeIn(250, function() {
						$wrap.removeClass('loading');
					});
				};

				image.src = $image;

			});
		},

		/**
		 * Populate the "Explore Product" link with query variables
		 */
		updateProductLink: function($this, $id, $swatch, $texture) {


			$btn = $('.explore-product-' + $id);
			$url = $btn.attr('href');

			if ($swatch) {
				$url = window.PageFunctions.updateSearchParameters($url, 'swatch', $swatch);
				$url = window.PageFunctions.updateSearchParameters($url, 'texture', '');
			}
			if ($texture) {
				$url = window.PageFunctions.updateSearchParameters($url, 'texture', $texture);
			}

			$btn.attr('href', $url);

		},

		/**
         * Product accordion functionality
         */
        productAccordions: function () {

        	// expand selected accordion
	        $('.single-product--accordion-title').on('click', function() {
	          $(this).next().slideToggle();
	          $(this).toggleClass('active');
	        });

	        // expand all accordions
	        $('.single-product--specifications-expand').on('click', function() {

	            $('.single-product--accordion-title').each(function() {
	                if (!$(this).hasClass('active')) {
	                  $(this).addClass('active');
	                  $(this).next().slideToggle();
	                }
	            });

	        });

	        // collapse all accordions
	        $('.single-product--specifications-collapse').on('click', function() {

	            $('.single-product--accordion-title').each(function() {
	                if ($(this).hasClass('active')) {
	                  $(this).removeClass('active');
	                  $(this).next().slideToggle();
	                }
	            });

	        });
	    },

		/**
		 * When a swatch is chosen
		 */
        swatch: function () {

    		
        	$(document).on('click', '.component--swatch', function(e){

				var $productID = $(this).parent().data('product-swatch');

	            if (!$(this).hasClass('active')) {

	              $('.product-id-' + $productID + ' .component--swatch').removeClass("active");
	              $(this).addClass("active");

	              $image = $(this).data('swatch-image');
	              $swatchTitle = $(this).attr('title');
	              $swatchIndex = $(this).data('swatch-index').toString(); // convert to string to handle '0'

	              swatchOptions[$productID].currentColour = $swatchTitle;

	              $('.product-id-' + $productID + ' .component--swatch-name.swatch-name-' + $productID + ' span').text($swatchTitle);

	              // Determine how the product is being displayed
	              if ($(this).hasClass('product') ) {

	              	$imageBGWrap = $('.dynamic-swatch-image-wrap');
	              	$imageBGElement = $('.dynamic-swatch-image');

	              } else if ($(this).hasClass('series') ) {

	              	$imageBGWrap = $('.dynamic-image-wrap-' + $productID);
	              	$imageBGElement = $('.dynamic-image-' + $productID);

	              	// update the explore products URL
	              	window.product.updateProductLink( $(this), $productID, $swatchIndex);

	              } else if ($(this).hasClass('designer') ) {

	              	window.designer.designerStep4(e, $(this));

	              	$('.component--swatch-name span').text($swatchTitle);

	              	$imageBGWrap = $('.dynamic-image-wrap-' + $productID);
	              	$imageBGElement = $('.dynamic-image-' + $productID);

	              }

	              window.product.replaceImage($imageBGElement, $imageBGWrap, $image);

	              /**
	               *  Populate Texture Options if the swatch has textures
	               */
		            if ($(this).hasClass('has-textures') ) {
			            data = {
			                'action': 'load_textures',
			                'pageID': $(this).data('pageid'),
			                'swatchTitle': $swatchTitle,
			                'swatchIndex': $swatchIndex,
			                'displayType': $(this).data('display-type'),
			                'is_default': false
			            };
			        } else {
			        	data = {
			                'action': 'load_textures',
			                'pageID': $(this).data('pageid'),
			                'swatchTitle': $swatchTitle,
			                'swatchIndex': $swatchIndex,
			                'displayType': $(this).data('display-type'),
			                'is_default': true
			            };
			        }
			              
		            $.ajax({
		                url : ajaxVars.ajaxurl,
		                data : data,
		                type : 'POST',
		                beforeSend : function ( xhr ) {
		                  $('.single-product--textures.product-textures-' + $productID).addClass('loading');
		                },
		                success : function( data ){
		                  if( data ) { 
		                    $('.single-product--textures.product-textures-' + $productID).html('');
		                    $('.single-product--textures.product-textures-' + $productID).removeClass('loading');
		                    $(".single-product--textures.product-textures-" + $productID).append(data);
		                  }
		                }
		            });

	            }

	        });

        },

        /**
		 * Texture selector
		 *
		 *  DEPRECATED
		 * 
		 */
        textures: function() {
	        $(document).on('click', '.single-product--texture', function(e){

	          $productID = $(this).data('product-id');

	          if (!$(this).hasClass('active')) {
	            $('.single-product--texture.product-texture-' + $productID).removeClass('active');
	            $(this).addClass('active');

	            $currentColour = swatchOptions[$productID].currentColour;
	            $swatchIndex = $(this).data('swatch');
	            $textureIndex = $(this).data('texture').toString(); // convert to string to handle '0'
	            $textureName = $(this).attr('title');
	            $assignedTextureColour = $(this).data('current-color');


	            if ($currentColour == 'default' || $assignedTextureColour == 'default') {
	            	$thumbnails = swatchOptions[$productID].default_textures;
	            } else {
		            $colours = swatchOptions[$productID].colours;
		            $thumbnails = $colours[$swatchIndex].swatch_thumbnails;
				}	
				
	            for(var i in $thumbnails) {

	              $thumbName = $thumbnails[i].texture;

	              if ($thumbName == $textureName) {

	               	  $image = $thumbnails[i].thumbnail.sizes.callout;

		              if ($(this).hasClass('product') ) {

		              	$imageBGWrap = $('.dynamic-swatch-image-wrap');
		              	$imageBGElement = $('.dynamic-swatch-image');

		              } else if ($(this).hasClass('series') ) {
		              	$imageBGWrap = $('.dynamic-image-wrap-' + $productID);
		              	$imageBGElement = $('.dynamic-image-' + $productID);

		              	// update the "explore products" URL
	              		window.product.updateProductLink( $(this), $productID, null, $textureIndex);

		              }
		              window.product.replaceImage($imageBGElement, $imageBGWrap, $image);
	                
	              }
	              
	            }

	          }
	        });
        },

		/**
         * Hot Spot functionality
         */
        seriesHotSpots: function() {

	        var $hotspot = $('.series--product-hotspots .hot-spot');
	        var $seriesListProductTitle = $('.series--hot-spot-product_title');

	        $hotspot.on('click', function(e) {
	          if (!$(this).hasClass('active')) {
	            $hotspot.removeClass("active");
	            $(this).addClass("active");
	            $seriesListProductTitle.next().slideUp();
	            $accordionID = $(this).attr('id');
	            $('.series--hot-spot-product_title[data-hotspot-id=' + $accordionID + ']').next().slideToggle();
	            $seriesListProductTitle.parent().removeClass('active');
	            $('.series--hot-spot-product_title[data-hotspot-id=' + $accordionID + ']').parent().addClass('active');
	          }
	        });


	        $seriesListProductTitle.on('click', function() {

	          if (!$(this).parent().hasClass('active')) {
	              $seriesListProductTitle.parent().removeClass('active');
	              $(this).parent().addClass('active');

	              $seriesListProductTitle.next().slideUp();
	              $(this).next().slideToggle();
	              $hotSpotID = $(this).data('hotspot-id');
	              $hotspot.removeClass("active");
	              $('#' + $hotSpotID).addClass('active');
	          }
	        });
        },
        /**
         * 	Convert Product headings to accordions on mobile
         */
        productHeadingAccordions: function() {

        	enquire.register("screen and (min-width:782px)", {
        		setup : function() {
        			$('.js-dynamic-accordion-title').addClass('accordion--title');
        			$('.js-dynamic-accordion-content').addClass('accordion--content');
        		},
        		match : function() {

        			$('.js-dynamic-accordion-title').removeClass('accordion--title');
        			$('.js-dynamic-accordion-content').removeClass('accordion--content');
        			
        		},
        		unmatch : function() {
        			$('.js-dynamic-accordion-title').addClass('accordion--title');
        			$('.js-dynamic-accordion-content').addClass('accordion--content');

        		}

        	});
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper