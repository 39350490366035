(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    
    var canBeLoaded = true;

    window.ajax = {

    	/**
    	 * Check if an element is in the viewport
    	 * @link https://medium.com/talk-like/detecting-if-an-element-is-in-the-viewport-jquery-a6a4405a3ea2
    	 */
    	checkIfElementInViewPort: function($el) {

			var elementTop = $el.offset().top,
				elementBottom = elementTop + $el.outerHeight(),
				viewportTop = $(window).scrollTop(),
				viewportBottom = viewportTop + $(window).height();

			return elementBottom > viewportTop && elementTop < viewportBottom;

    	},

    	/**
    	 * Global load more button functionality
    	 */
        loadMoreTeamMembers: function (){

			var bottomOffset = 2000; // the distance (in px) from the page bottom when you want to load more posts

			$(window).scroll(function(){

		        var button = $('.load-more-button'),
		            cat = button.data('category'),
		            ppp = button.data('ppp'),
		            cpt = button.data('cpt'),
		            tax = button.data('tax'),
		            term = button.data('term'),
		            order = button.data('order'),
		            orderby = button.data('orderby'),
		            max = button.data('max'),
		            text = button.data('text'),
		            exclude = button.data('exclude'),
		            search = button.data('s'),
		            templateType = button.data('templatetype'),
		            department = button.data('department'),
		            location = button.data('location'),
		            data = {
		              'page' : ajaxVars.current_page,
		              'action': 'more_post_ajax',
		              'order' : order, 
		              'orderby' : orderby,
		              'cpt' : cpt,
		              'cat' : cat,
		              'ppp' : ppp,
		              'tax' : tax,
		              'term' : term,
		              'max' : max,
		              'exclude' : exclude,
		              'search' : search,
		              'location' : location,
		              'department' : department
		            };

				if( window.ajax.checkIfElementInViewPort( $('.footer-callouts') ) && canBeLoaded == true && button.length ){

		            $.ajax({
		              url : ajaxVars.ajaxurl, // AJAX handler
		              data : data,
		              type : 'POST',
		              beforeSend : function ( xhr ) {
		                button.text('Loading...'); // change the button text
						canBeLoaded = false; 
		              },
		              success : function( data ){
		                if( data ) {
		                  $loadContainer = $('.team-members--team-members-results').find('#ajax-container');
		                  $(data).hide().appendTo($loadContainer).fadeIn(1000);
		                  button.text( text );
		                  ajaxVars.current_page++;
   						  canBeLoaded = true; // the ajax is completed, now we can run it again


		                  if ( ajaxVars.current_page >= max )  {
		                    button.remove(); // if last page, remove the button
		                    $('.load-more-button-container').remove();
		                  }
		         
		                  
		                } else {
		                  button.remove(); // if no data, remove the button as well
		                  $('.load-more-button-container').remove();
		                }
		              }
		            });
		        }
	        });
        },

		/**
         * Load in the content on select change
         */
        loadMoreTeamMembersSelect: function() {
			$(document).on('change', '.team-members--select', function(){

				canBeLoaded = true;

	            $blockURL = $(this).data('block-url');

	            if ($(this).attr('name') == 'location') {
	                $department = $('.team-members--select[name="department"]').val();
	                $location = $(this).val();
	                $blockURL = $(this).data('block-url') + '?department='+$department+'&location='+$location;
	            } else if ($(this).attr('name') == 'department') {
	                $location = $('.team-members--select[name="location"]').val();
	                $department = $(this).val();
	                $blockURL = $(this).data('block-url') + '?department='+$department+'&location='+$location;
	            }


	            $.ajax({
	               url: $blockURL,
	               data: {},
	               beforeSend : function ( xhr ) {
	                // add the loading class
	                $('.dynamic-content').addClass('loading');
	               },
	               success: function (data) {
	                  // reset the current page var
	                  ajaxVars.current_page = 1;

	                  // remove the load more button
	                  $(".load-more-button-container").remove();

	                  // set the number of results
	                  $resultsContainer = $(data).find('.component-filter--results-container').html();
	                  $numberOfResults = $(data).find('.component-filter--number-of-results');

	                  if ($numberOfResults.html() < 1) {
	                    $('.component-filter--team-members-results').addClass('hidden');
	                  } else {
	                    $('.component-filter--results_info-results').removeClass('hidden');
	                  }
	                  console.log($resultsContainer);
	                  $('.component-filter--results_info-results').html($resultsContainer);

	                  // load in the content
	                  $("#ajax-container").html($(data).find(".team-members--item"));
	                  $(".dynamic-content").append($(data).find(".load-more-button-container"));
	                  // remove the loading class
	                  $('.dynamic-content').removeClass('loading');
	               },
	               dataType: 'html'
	            });

	        });
        },
        /**
         * Load in Projects on select change
         */
        loadMoreProjectsSelect: function() {
			$(document).on('change', '.featured-projects--select', function(){

				canBeLoaded = true;

	            $blockURL = $(this).data('block-url');

	            if ($(this).attr('name') == 'category') {
	                $product_used = $('.featured-projects--select[name="product_used"]').val();
	                $category = $(this).val();
	                $blockURL = $(this).data('block-url') + '?category='+$category+'&product_used='+$product_used;
	            } else if ($(this).attr('name') == 'product_used') {
	            	$category = $('.featured-projects--select[name="category"]').val();
	                $product_used = $(this).val();
	                $blockURL = $(this).data('block-url') + '?category='+$category+'&product_used='+$product_used;
	            }


	            $.ajax({
	               url: $blockURL,
	               data: {},
	               beforeSend : function ( xhr ) {
	                // add the loading class
	                $('.ajax-row').addClass('loading');
	               },
	               success: function (data) {
	                  // reset the current page var
	                  ajaxVars.current_page = 1;

	                  // remove the load more button
	                  $(".load-more-button-container").remove();

	                  // set the number of results
	                  $resultsContainer = $(data).find('.component-filter--results-container').html();
	                  $numberOfResults = $(data).find('.component-filter--number-of-results');

	                  if ($numberOfResults.html() < 1) {
	                    $('.component-filter--team-members-results').addClass('hidden');
	                  } else {
	                    $('.component-filter--results_info-results').removeClass('hidden');
	                  }
	                  console.log($resultsContainer);
	                  $('.component-filter--results_info-results').html($resultsContainer);

	                  // load in the content
	                  $(".ajax-row").html($(data).find(".featured-project--col-item"));
	                  $(".featured-project--container").append($(data).find(".load-more-button-container"));
	                  // remove the loading class
	                  $('.ajax-row').removeClass('loading');
	               },
	               dataType: 'html'
	            });

	        });
        },
        /**
         * Load the designer steps
         */
        loadDesignerStep: function(e, $this) {


        	e.preventDefault();
        	
        	if (!$this.hasClass('disabled')) {

	    		var direction = '',
	    			step = '';

	    		// if we're going forward
	    		if ($this.hasClass('space-designer--next')) {
	    			direction = 'forward';
	        		step = parseInt(designerVars.current_step) + 1;

	        	// if we're going backwards
	        	} else if ($this.hasClass('space-designer--prev')) {
	        		direction = 'reverse';
	        		step = parseInt(designerVars.current_step) - 1;
	        	} else if ( $this.hasClass('space-designer--change') ) {
	        		designerVars.current_step = $this.data('current-step');
	        		step = designerVars.current_step;

	        	// on radio button change
	        	
	        	} else {
	        		direction = 'forward';
	        		step = parseInt(designerVars.current_step) + 1;
	        	}

	        	// data
	        	
	        	$type = $('#type').val();
	        	$area = $('#area').val();
	        	$product_name = $('#product_name').val();
	        	$swatch_index = $('#swatch_index').val();

	        	data = {
	        		'step' : step,
	        		'type' : $type ? $type : '',
	        		'area' : $area ? $area : '',
	        		'product_name' : $product_name ? $product_name : '',
	        		'swatch_index' : $swatch_index ? $swatch_index : '',
	        	}

				$.ajax({
	              url : designerVars.ajaxurl, // AJAX handler
	              data : data,
	              type : 'POST',
	              beforeSend : function ( xhr ) {
	                $('.space-designer--wrap').addClass("loading");
	              },
	              success : function( data ){
	                if( data ) { 

	                  content = $(data).find('#ajax-row');

	                  $('#ajax-container').html(content);
	                  $('.space-designer--wrap').removeClass("loading");

	                  if (direction == 'forward') {
	                  	designerVars.current_step++;
	                  } else if (direction == 'reverse') {
	                  	designerVars.current_step--;
	                  }

	                  window.designer.pushDesignerHistory( designerVars.ajaxurl, 'step', designerVars.current_step );

	                }
	              }
	            });
			}
        },

        /**
         * Load in the inspiration terms
         */
        loadInspirations: function() {
	        
	        $('body').on('click', '.inspiration--terms-list a', function(e) {

	        	$('.inspiration--terms-list a').removeClass('active');
	        	$(this).addClass('active');

	        	e.preventDefault();
	        	var $tax = $(this).data('tax'),
	        		$term = $(this).data('term')
	        		reset = false;


	        	if ($(this).hasClass('reset')) {
	        		$tax = '';
	        		$term = '';
	        		reset = true;
	        	}

		        var data = {
	              'tax' : $tax,
	              'term' : $term
	            };	            
	     
	            $.ajax({
	              url : ajaxVars.current_url,
	              data : data,
	              type : 'POST',
	              beforeSend : function ( xhr ) {
	                $('.ajax-container').addClass("loading");
	              },
	              success : function( data ){
	                if( data ) { 

						$('.ajax-container').removeClass("loading");
						$ajaxContent = $(data).find(".ajax-row");
	                    $(".ajax-container").html($ajaxContent);

						if (reset) {
		                 	$newState = ajaxVars.current_url;
		              	} else {
		              		$newState = ajaxVars.current_url + '?tax=' + $tax + '&term=' + $term;
		              	}

				        history.replaceState(null, null, $newState);

						// init Isotope
						var $grid = $ajaxContent.isotope({
							itemSelector: '.inspiration--item'
						});
						// layout Isotope after each image loads
						$grid.imagesLoaded().progress( function() {
							$grid.isotope('layout');
						});

	              	}
	              }
	            });
	        });
	        
	    }
    };

}(window.jQuery, window, document)); // End of jQuery wrapper